import client from '@mapbox/mapbox-sdk';
import staticMapService from '@mapbox/mapbox-sdk/services/static';

const baseClient = client({
    accessToken: process.env.VUE_APP_MAPBOX_KEY
});
const service = staticMapService(baseClient);

export const staticMap = async (markers) => service.getStaticImage({
    ownerId: 'mapbox',
    styleId: 'streets-v11',
    width: 920,
    height: 920,
    overlays: [...markers],
    position: 'auto',
    padding: '4',
}).url()

    // .send()
    // .then(response => {
    //     return response.body;
    // });