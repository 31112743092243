<template>
  <v-input hide-details>
    <v-text-field
        v-if="readonly"
        prepend-icon="mdi-arrow-right"
        append-icon=""
        dense hide-details solo flat
        readonly
        :value="value.place_name"></v-text-field>
    <v-autocomplete
        ref="input"
        v-else
        placeholder="Location"
        dense hide-details solo flat
        :search-input.sync="search"
        :items="locations"
        :return-object="true"
        item-text="place_name"
        item-value="id"
        :cache-items="true"
        :value="value"
        @change="v => onInput(v)"
        append-icon=""
    >
      <template v-slot:prepend-inner>
        <div>
          <v-icon left size="20">mdi-arrow-right</v-icon>
        </div>
      </template>
      <template v-slot:no-data>
        <v-list-item @click="() => {$refs.input.focus()}">
          <v-list-item-subtitle>No data available. Please start typing to search.</v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-input>
</template>

<script>
import {createLocation} from "../../store/models/Location";
import {debounce} from "lodash";
import {geocode} from "../../services/geocoder";

export default {
  name: "LocationPicker",
  props: {
    value: {
      type: Object,
      default: () => createLocation({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    locations: [],
  }),
  computed: {
    search: {
      get() {
        return '';
      },
      set(v) {
        if (v && v.length > 0) this.fetch(v);
      }
    },
  },
  methods: {
    fetch: debounce(async function (query) {
      this.locations = await geocode(query);
    }, 1000),
    onInput(value) {
      this.$emit('input', createLocation(value));
    }
  },
  mounted() {
    if (this.value) this.locations = [{...this.value}];
  },
}
</script>