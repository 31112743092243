<template>
  <v-menu ref="menu"
          v-model="menu"
          :close-on-click="false"
          :close-on-content-click="false"
          :disabled="readonly"
          min-width="500"
          offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          label
          :class="`status status--${state} rounded-sm`"
          v-bind="attrs" v-on="on">
        <span class="font-weight-medium">{{ state || 'Pick status' }} {{ timestamp }}</span>
      </v-chip>
    </template>
    <template v-slot:default>
      <v-card>
        <v-row>
          <v-col>
            <v-list dense flat>
              <v-list-item-group v-model="buffer.state" :value="state" mandatory>
                <v-list-item
                    v-for="item in states"
                    :key="item.value"
                    :value="item.value"
                    v-slot="{ active }"
                >
                  <v-chip
                      :class="active ? 'rounded-sm' : `status status--${item.value} rounded-sm`"
                      :input-value="active"
                      label
                      filter
                  ><span class="font-weight-medium">{{ item.text }}</span></v-chip>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col>
            <v-date-picker color="primary"
                           no-title
                           flat
                           v-model="buffer.timestamp"
                           :weekday-format="(d) => {
                             let i = new Date(d).getDay(d);
                             return ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][i];
                           }"
                           first-day-of-week="0"
            ></v-date-picker>
          </v-col>
        </v-row>
        <div class="px-4 py-2" v-if="errors.length > 0">
          <div v-for="(error,i) in errors"
               class="text-caption error--text"
               :key="`e-${index}-${i}`" v-text="error"></div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="cancel()">Cancel</v-btn>
          <v-btn :loading="loading" depressed color="success" @click="save()" :disabled="!state || !timestamp">Save</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-menu>
</template>

<script>
import {find} from 'lodash';
import states from "../../store/enums/states";
import api from "../../http/api";

export default {
  name: "StatePickerAsync",
  props: {
    uuid: {
      type: String,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: null,
    },
    timestamp: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    menu: false,
    states: [...states],
    loading: false,
    buffer: {
      timestamp: null,
      state: null,
    }
  }),
  watch: {
    state: function(v) {
      this.buffer.state = v;
    },
    timestamp: function(v) {
      this.buffer.timestamp = v;
    },
  },
  mounted() {
    this.buffer.state = this.state;
    this.buffer.timestamp = this.timestamp;
  },
  methods: {
    mapStateColor(state) {
      const found = find(this.states, (e) => e.value === state);
      if (found) return found.color;
      return 'error';
    },
    updateState(v) {
      this.$emit('update:state', v)
    },
    updateTimestamp(v) {
      this.$emit('update:timestamp', v)
    },
    save() {
      this.loading = true;
      api.order
          .updateWaypoint(this.order, this.uuid, this.buffer)
          .then(({data}) => {
            this.$emit('update:timestamp', this.buffer.timestamp);
            this.$emit('update:state', this.buffer.state)
            this.$emit('input', {
              timestamp: this.buffer.timestamp,
              state: this.buffer.state
            });
            this.$emit('change', {
              timestamp: this.buffer.timestamp,
              state: this.buffer.state
            });
            this.menu = false;
          })
          .catch(error => {
            throw error;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    cancel() {
      this.buffer.state = this.state;
      this.buffer.timestamp = this.timestamp;
      this.menu = false;
    }
  }
}
</script>