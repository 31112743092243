<template>
  <v-sheet color="primary" rounded class="fill-height" style="position: relative; overflow: hidden;">
    <v-sheet class="d-flex align-stretch flex-column fill-height">

      <v-sheet color="transparent" class="overflow-hidden flex-grow-0 flex-shrink-0">
        <v-card v-if="isManager" flat color="grey lighten-4" class="mb-4 overflow-hidden">
          <v-card-text>
            <v-row class="mb-2">
              <v-col cols="4" class="flex-shrink-0">
                <span class="grey&#45;&#45;text">Your client</span>
              </v-col>
              <v-col class="flex-grow-1 flex-shrink-0 text-right">
                <span class="font-weight-medium">{{ client.company.name }}</span>
              </v-col>
            </v-row>
            <v-list-item class="px-0">
              <v-list-item-avatar size="72">
                <Avatar size="72" :layout="{rounded: false}" :subject="client"></Avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-2 font-weight-medium">{{ client.name || '—' }}</v-list-item-title>
                <v-list-item-subtitle>{{ client.phone || '—' }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ client.email || '—' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card v-else flat color="grey lighten-4" class="mb-4">
          <v-card-text>
            <v-row class="mb-2">
              <v-col class="flex-shrink-0">
                <span class="grey&#45;&#45;text">Your personal manager</span>
              </v-col>
            </v-row>
            <v-list-item class="px-0">
              <v-list-item-avatar size="72">
                <Avatar size="72" :layout="{rounded: false}" :subject="manager"></Avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-2 font-weight-medium">{{ manager.name || '—' }}</v-list-item-title>
                <v-list-item-subtitle>{{ manager.phone || '—' }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ manager.email || '—' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-sheet>

      <v-sheet color="grey lighten-4" class="flex-grow-1 rounded-t rounded-b-0 overflow-hidden" style="position: relative;">


        <v-card flat color="grey lighten-4" class="pa-2 rounded-b-0 overflow-y-auto"
                style="position: absolute; left: 0; top: 0; bottom: 0;"
                id="scroll-target">
          <v-list v-if="comments" color="transparent" class="py-0" id="scrolled-content">
            <template v-for="comment in comments">
              <v-list-item :key="comment.uuid" class="white mb-3 rounded">
                <v-list-item-avatar size="42" class="align-self-start">
                  <Avatar size="42" :subject="comment.author"></Avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-body-2">{{ comment.author.name }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{ comment.body }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="mt-auto mb-1">
                  {{ date(comment.created_at, 'hh:mm DD/MM/YY') }}
                </v-list-item-action-text>
              </v-list-item>
            </template>
          </v-list>
        </v-card>

      </v-sheet>

      <v-sheet color="grey lighten-4" class="mt-auto pa-2">
        <v-form ref="form" class="d-flex" @submit.prevent="postMessage()" :disabled="sending">
          <v-text-field
              :loading="sending"
              aria-autocomplete="false"
              v-model="body"
              class="rounded"
              full-width filled placeholder="Write something" background-color="white"
              @click:append="() => postMessage()"
              append-icon="mdi-send"
              :error-messages="getErrors('body')"
              :hide-details="true"
              @change="unsetError('body')"
          >
          </v-text-field>
        </v-form>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Avatar from "../common/Avatar";
import {createClient, createUser} from "../../store/models/User";

export default {
  name: "ChatWidget",
  // eslint-disable-next-line vue/no-unused-components
  components: {Avatar},
  props: {
    subject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('auth', {
      currentUserUuid: 'uuid',
      currentUserRole: 'role',
    }),
    ...mapState('comment', {
      comments: 'items',
      loading: 'loading',
      sending: 'sending'
    }),
    ...mapGetters('error', {
      hasErrors: 'hasErrors',
      getErrors: 'getErrors',
    }),
    isManager() {
      return this.currentUserRole === 'manager' || this.currentUserRole === 'admin';
    },
    client() {
      return this.subject.client || createClient({name: 'Unknown client'});
    },
    manager() {
      return this.subject.manager || createUser({name: 'Unknown manager'});
    }
  },
  data: () => ({
    body: '',
    stream: null,
  }),
  methods: {
    ...mapActions('comment', {
      fetch: 'fetch',
      send: 'send'
    }),
    ...mapActions('order', {
      watchOrder: 'watch'
    }),
    ...mapActions('error', {
      unsetError: 'unset',
      clearErrors: 'clear',
    }),
    async postMessage() {
      await this.send({
        subject_uuid: this.subject.uuid,
        body: this.body,
      });
      this.body = '';
      this.scrollChat();
    },
    scrollChat() {
      try {
        const elem = document.getElementById("scrolled-content");
        const container = document.getElementById("scroll-target");
        container.scrollTop = Math.floor(elem.offsetHeight);
      } catch (e) {
        // ...
      }
    }
  },
  async mounted() {
    this.fetch(this.subject.uuid);
  },
  watch: {
    loading: function (v, o) {
      if (v == false && o == true) this.scrollChat();
    }
  }
}
</script>