<template>
  <div class="waypoint">
    <v-card outlined class="pa-2">
      <v-row dense class="justify-space-between align-center mb-1">
        <v-col class="flex-grow-1">
          <slot name="location"></slot>
        </v-col>
        <v-col class="flex-grow-0">
          <slot name="transport"></slot>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <slot name="state"></slot>
        </v-col>
      </v-row>
      <div class="mt-2" v-if="errors.length > 0">
        <div v-for="(error,i) in errors"
             class="text-caption error--text"
             :key="`e-${index}-${i}`" v-text="error"></div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Waypoint",
  props: {
    index: {
      type: Number,
      required: true,
    },
    errors: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>