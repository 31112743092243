const transports = [
    {
        value: 'ship',
        text: 'ship',
        icon: 'mdi-ferry'
    },
    {
        value: 'truck',
        text: 'truck',
        icon: 'mdi-truck'
    },
    {
        value: 'plane',
        text: 'plane',
        icon: 'mdi-airplane'
    },
    {
        value: 'train',
        text: 'train',
        icon: 'mdi-train'
    }
];

export default transports;