const STATIC_URL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : ""
const states = [
    {
        value: 'scheduled',
        text: 'scheduled',
        color: 'secondary',
        hexColor: '#5B5B5B',
        class: 'secondary--text secondary lighten-4',
        marker: `/icons/marker_scheduled.png`,
    },
    {
        value: 'departured',
        text: 'departured',
        color: 'success',
        hexColor: '#1ABC00',
        class: 'success--text success lighten-5',
        marker: `/icons/marker_departured.png`,
    },
    {
        value: 'delivered',
        text: 'delivered',
        color: 'success',
        hexColor: '#1ABC00',
        class: 'success--text success lighten-5',
        marker: `/icons/marker_delivered.png`,
    },
    {
        value: 'arriving',
        text: 'arriving',
        color: 'warning',
        hexColor: '#C8930C',
        class: 'warning--text warning lighten-5',
        marker: `/icons/marker_arriving.png`,
    },
    {
        value: 'canceled',
        text: 'canceled',
        color: 'error',
        hexColor: '#F64A4A',
        class: 'error--text error lighten-5'
    },
]
export default states;