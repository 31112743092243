<template>
  <v-select
      :readonly="readonly"
      append-icon=""
      hide-details
      solo
      dense
      flat
      item-value="value"
      item-text="text"
      :value="value"
      @change="v => $emit('input', v)"
      :menu-props="{maxWidth: 76, nudgeLeft: 60}"
      :items="items"
  >
    <template v-slot:item="{item, on, attrs}">
      <v-list-item dense v-on="on" v-bind="attrs" v-show="item.value != null" >
        <v-list-item-content>
          <v-avatar color="grey lighten-3" rounded size="36">
            <v-icon v-text="item.icon"></v-icon>
          </v-avatar>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{item,}">
      <v-avatar color="grey lighten-3" rounded="sm" size="32" class="mr-n2">
        <v-icon size="16" v-text="item.icon"></v-icon>
      </v-avatar>
    </template>
  </v-select>
</template>

<script>
import transports from "../../store/enums/transports";

export default {
  name: "TransportSelect",
  props: {
    value: {
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    items: [
      {
        value: null,
        text: 'select',
        icon: 'mdi-dots-horizontal'
      },
      ...transports
    ]
  })
}
</script>