<template>
  <v-img class="rounded" contain aspect-ratio="1" :src="url"></v-img>
</template>

<script>
import {staticMap} from "../../services/staticMap";
import {find} from "lodash";
import states from "../../store/enums/states";

export default {
  name: "StaticMap",
  props: [
      'value'
  ],
  data: () => ({
    url: null,
  }),
  async mounted() {
    let markers = this.mapWaypointsToMarkers(this.value);
    this.url = await staticMap(markers);
  },
  watch: {
    value: {
      deep: true,
      handler: async function (v, o) {
        let markers = this.mapWaypointsToMarkers(v);
        this.url = await staticMap(markers);
      }
    }
  },
  methods: {
    mapWaypointsToMarkers(route) {
      const markers = route.map((wp,i) => {
        let found = find(states, s => s.value === wp.state);
        let color = found ? found.hexColor : '#F64A4A';
        return {
          marker: {
            coordinates: wp.location.center,
            size: 'large',
            color: color,
            // url: found.marker
          }
        }
      });
      return [...markers];
    }
  }
}
</script>