<template>
  <v-layout fill-height column>
    <v-progress-linear
      value="true"
      v-if="loading"
      indeterminate
    ></v-progress-linear>
    <v-container fluid v-if="instance">
      <v-row>
        <v-col>
          <v-btn small depressed :to="{ name: 'order.index' }" exact>
            <v-icon left>mdi-arrow-left</v-icon>
            <span>Back to orders</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1>{{ name }}</h1>
          <div
            class="body-2 mt-3"
            v-if="instance.description"
            v-text="instance.description"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="flex-grow-0" v-if="isManager">
          <v-btn
            depressed
            :to="{ name: 'order.edit', params: { uuid: instance.uuid } }"
            >Edit</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="instance">
      <v-row class="flex-md-nowrap align-stretch" style="position: relative">
        <v-col md="4" class="waypoints d-flex flex-shrink-1 flex-column">
          <Waypoint
            v-for="(waypoint, i) in route"
            :key="`route-${i}`"
            :index="i"
            :errors="routeErrors(i)"
          >
            <template v-slot:location>
              <LocationPicker
                :readonly="true /*!isManager*/"
                v-model="waypoint.location"
              />
            </template>
            <template v-slot:transport>
              <TransportSelect
                :readonly="true /*!isManager*/"
                v-model="waypoint.transport"
              />
            </template>
            <template v-slot:state>
              <StatePickerAsync
                :readonly="!isManager"
                :uuid="waypoint.uuid"
                :order="instance.uuid"
                v-bind:state.sync="waypoint.state"
                v-bind:timestamp.sync="waypoint.current_timestamp"
                :errors="stateErrors(i)"
                @change="unsetError('state')"
              />
            </template>
          </Waypoint>
        </v-col>
        <v-col md="5" class="">
          <StaticMap v-if="instance" :value="route"></StaticMap>
        </v-col>
        <v-col md="4" class="flex-shrink-1" style="position: relative">
          <ChatWidget :subject="instance" :manager="isManager"></ChatWidget>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StaticMap from "../../components/order/StaticMap";
import { mapMultiRowFields } from "vuex-map-fields";
import TransportSelect from "../../components/order/TransportSelect";
import LocationPicker from "../../components/order/LocationPicker";
import ChatWidget from "../../components/order/ChatWidget";
import Waypoint from "../../components/order/Waypoint";
import { concat } from "lodash";
import StatePickerAsync from "../../components/order/StatePickerAsync";

export default {
  name: "OrderViewer",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    StatePickerAsync,
    Waypoint,
    ChatWidget,
    LocationPicker,
    TransportSelect,
    StaticMap,
  },
  props: ["uuid"],
  computed: {
    ...mapState("order/instance", {
      instance: "instance",
      loading: "loading",
    }),
    ...mapGetters("order/instance", {
      currentState: "currentState",
    }),
    ...mapMultiRowFields("order/instance", {
      route: "instance.route",
    }),
    ...mapGetters("error", {
      hasErrors: "hasErrors",
      getErrors: "getErrors",
    }),
    ...mapState("auth", {
      role: "role",
    }),
    routeErrors() {
      return (i) =>
        concat(
          this.getErrors(`route.${i}.location`),
          this.getErrors(`route.${i}.transport`),
          this.getErrors(`route.${i}.state`),
          this.getErrors(`route.${i}.current_timestamp`)
        );
    },
    stateErrors() {
      return (i) => concat(this.getErrors(`state`));
    },
    isManager() {
      return this.role === "admin" || this.role === "manager";
    },
    name() {
      const orderName = this.instance.name;

      return orderName ? orderName : `Order #${this.instance.num}`;
    },
  },
  methods: {
    ...mapActions("order/instance", {
      show: "show",
      edit: "edit",
      cancel: "cancel",
    }),
    ...mapActions("error", {
      unsetError: "unset",
      clearErrors: "clear",
    }),
    updateWaypoint(index, value) {
      Object.keys(value).forEach((key) => {
        console.info(key, value[key]);
        this.route[key] = value[key];
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    const module = "order";
    if (to.name === `${module}.show`) {
      next((vm) => {
        // vm.fetch();
        vm.show(to.params.uuid);
      });
      return;
    }
    if (to.name === `${module}.edit`) {
      next((vm) => {
        // vm.fetch();
        vm.edit(to.params.uuid);
      });
      return;
    }
  },

  beforeRouteUpdate(to, from, next) {
    const module = "order";
    // console.info('beforeRouteUpdate: to:' + to.name);
    if (to.name === `${module}.show`) {
      this.cancel();
      this.show(to.params.uuid);
    }
    if (to.name === `${module}.edit`) {
      this.cancel();
      this.edit(to.params.uuid);
    }
    next();
  },

  beforeDestroy: function () {
    this.clearErrors();
  },
};
</script>
