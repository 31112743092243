import client from '@mapbox/mapbox-sdk';
import geocodingService from '@mapbox/mapbox-sdk/services/geocoding';

const baseClient = client({
    accessToken: process.env.VUE_APP_MAPBOX_KEY
});
const geocoder = geocodingService(baseClient);

export const geocode = async (query) => geocoder.forwardGeocode({
    query: query,
    types: [
        'country',
        'region',
        'postcode',
        'district',
        'place',
        'locality',
        'neighborhood',
        'address',
        'poi'
    ],
})
    .send()
    .then(response => (response.body.features));